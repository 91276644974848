import CryptoJS from "crypto-js";

//================== [CONSTANT KEYS] ==================
const KEY_AUTH = "key_auth";
const KEY_TOKEN = "key_token";
const KEY_TEMP_RETURN_URL = "KEY_TEMP_RETURN_URL";
const KEY_PWA_VISITED = "KEY_PWA_VISITED";
const KEY_SELECTED_CAR = "KEY_SELECTED_CAR";
const KEY_SETUP_STEP = "KEY_SETUP_STEP";
const KEY_SETUP_SERVICES = "KEY_SETUP_SERVICES";
const KEY_SETUP_CAR = "KEY_SETUP_CAR";
const KEY_SETUP_RETURN_URL = "KEY_SETUP_RETURN_URL";
const KEY_SETUP_FLOW_VISITED = "KEY_SETUP_FLOW_VISITED";
const KEY_VERSION_VISITED = "KEY_VERSION_VISITED";

const env = import.meta.env;

const ServiceStorage = {
  //!--------------- Temp Return URL
  setTempReturnURL: (pathFromConfigLink: string | null) => {
    if (pathFromConfigLink) {
      localStorage.setItem(KEY_TEMP_RETURN_URL, pathFromConfigLink);
    } else {
      localStorage.removeItem(KEY_TEMP_RETURN_URL);
    }
  },
  getTempReturnURL: (): string | null => {
    return localStorage.getItem(KEY_TEMP_RETURN_URL);
  },
  removeTempReturnURL: (): void => {
    localStorage.removeItem(KEY_TEMP_RETURN_URL);
  },
  //!--------------- Auth
  setAuth: (auth: any) => {
    localStorage.setItem(KEY_AUTH, JSON.stringify(auth));
  },
  getAuth: (): any | null => {
    return JSON.parse(localStorage.getItem(KEY_AUTH) as string);
  },
  removeAuth: (): boolean => {
    localStorage.removeItem(KEY_AUTH);
    return true;
  },
  setToken: (token: string) => {
    localStorage.setItem(KEY_TOKEN, token);
  },
  getToken: (): string | null => {
    return localStorage.getItem(KEY_TOKEN) as string;
  },
  removeToken: (): boolean => {
    localStorage.removeItem(KEY_TOKEN);
    return true;
  },
  //------------- pwa modal
  setPWAVisitStatus: () => {
    localStorage.setItem(KEY_PWA_VISITED, "true");
  },
  getPWAVisitStatus: (): string | null => {
    return localStorage.getItem(KEY_PWA_VISITED);
  },
  //------------- SELECTED CAR
  setSelectedCar: (id: string) => {
    localStorage.setItem(KEY_SELECTED_CAR, id);
  },
  getSelectedCar: (): string | null => {
    return localStorage.getItem(KEY_SELECTED_CAR) as string;
  },
  removeSelectedCar: (): boolean => {
    localStorage.removeItem(KEY_SELECTED_CAR);
    return true;
  },

  //--------------- setup
  setStep: (step: number) => {
    localStorage.setItem(KEY_SETUP_STEP, step.toString());
  },
  getStep: (): string | null => {
    return localStorage.getItem(KEY_SETUP_STEP);
  },
  removeStep: (): void => {
    localStorage.removeItem(KEY_SETUP_STEP);
  },

  setSetupServices: (services: any[]) => {
    localStorage.setItem(KEY_SETUP_SERVICES, JSON.stringify(services));
  },
  getSetupServices: (): string | null => {
    return localStorage.getItem(KEY_SETUP_SERVICES);
  },
  removeSetupServices: (): void => {
    localStorage.removeItem(KEY_SETUP_SERVICES);
  },

  setSetupCar: (id: string) => {
    localStorage.setItem(KEY_SETUP_CAR, id);
  },
  getSetupCar: (): string | null => {
    return localStorage.getItem(KEY_SETUP_CAR);
  },
  removeSetupCar: (): void => {
    localStorage.removeItem(KEY_SETUP_CAR);
  },

  setSetupReturnURL: (pathFromConfigLink: string | null) => {
    if (pathFromConfigLink) {
      localStorage.setItem(KEY_SETUP_RETURN_URL, pathFromConfigLink);
    } else {
      localStorage.removeItem(KEY_SETUP_RETURN_URL);
    }
  },
  getSetupReturnURL: (): string | null => {
    return localStorage.getItem(KEY_SETUP_RETURN_URL);
  },
  removeSetupReturnURL: (): boolean => {
    localStorage.removeItem(KEY_SETUP_RETURN_URL);
    return true;
  },

  setSetupFlowVisited: () => {
    localStorage.setItem(KEY_SETUP_FLOW_VISITED, "1");
  },
  getSetupFlowVisited: (): string | null => {
    return localStorage.getItem(KEY_SETUP_FLOW_VISITED);
  },
  removeSetupFlowVisited: (): void => {
    localStorage.removeItem(KEY_SETUP_FLOW_VISITED);
  },
  setVersionVisited: (version: string) => {
    localStorage.setItem(KEY_VERSION_VISITED, version);
  },
  getVersionVisited: (): string | null => {
    return localStorage.getItem(KEY_VERSION_VISITED);
  },
  removeVersionVisited: (): void => {
    localStorage.removeItem(KEY_VERSION_VISITED);
  },
};

export { ServiceStorage };
