//@ts-nocheck
/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useSnapshot } from "valtio";
import { vState } from "../../core";

interface IContainerScrollProps {
  heightOffset?: number | undefined;
  noPadding?: boolean;
  id?: string;
  children?: any;
  disableScroll?: boolean;
  className?: string;
  onScroll?: (e) => void;
}

const ContainerScroll = React.forwardRef(
  (
    {
      className = "",
      children,
      heightOffset = undefined,
      noPadding = false,
      id = undefined,
      disableScroll = false,
      onScroll,
    }: IContainerScrollProps,
    ref,
  ) => {
    const snap = useSnapshot(vState);
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [lastCarStatusScrollTop, setLastCarStatusScrollTop] = useState(0);
    const [isShrunk, setShrunk] = useState(false);

    const isLargeScreen = useMediaQuery({
      query: "(min-height: 960px)",
    });

    useEffect(() => {
      vState.isScrolling = false;
      vState.isCarStatusScroll = false;
    }, []);

    function navbarControlHandler(e) {
      let st = window.pageYOffset || document.documentElement.scrollTop;

      if (st <= 20) {
        vState.isScrolling = false;
      }

      if (st > lastScrollTop && st > 20) {
        // downScroll code
        if (!snap.isScrolling) {
          vState.isScrolling = true;
        }
      } else {
        // upScroll code
        if (st <= 10) {
          if (snap.isScrolling) {
            vState.isScrolling = false;
          }
        }
      }
      setLastScrollTop(st <= 0 ? 0 : st); // For Mobile or negative scrolling

      if (window.innerHeight + window.scrollY >= document.body.scrollHeight - 1.5) {
        console.log("REACHED END OF PAGE");
        vState.isEndOfPage = true;
      }
    }

    useEffect(() => {
      window.addEventListener("scroll", navbarControlHandler);

      return () => {
        window.removeEventListener("scroll", navbarControlHandler);
      };
    }, []);

    // useEffect(() => {
    //   const updateScrollDirection = () => {
    //     let st = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

    //     if (st > 0) {
    //       vState.isCarStatusScroll = true;
    //       // main.style.marginTop = `${header.clientHeight}px`;
    //     } else {
    //       vState.isCarStatusScroll = false;
    //       // main.style.marginTop = 0;
    //     }
    //   };
    //   window.addEventListener("scroll", updateScrollDirection);
    //   return () => {
    //     window.removeEventListener("scroll", updateScrollDirection);
    //   };
    // }, []);

    return (
      <div
        id="container-scroll"
        style={{
          position: "unset",
          height: "inherit",
          paddingBottom: heightOffset,
          paddingTop: noPadding ? "" : "88px",
        }}
        className={`w-full overflow-x-hidden overflow-y-auto ts-hide-scroll-but-keep-working ${
          noPadding ? "" : "px-6 max-2xs:px-3"
        } z-30 ${className}`}
      >
        {children}
      </div>
    );
  },
);

export { ContainerScroll };
