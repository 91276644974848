/* eslint-disable */
import { ConfigLink, ServiceStorage, useQueryString, vState } from "core";
import moment from "moment";
import React from "react";
import { GradientCircularProgress } from "react-circular-gradient-progress";
import { useHistory } from "react-router-dom";
import { useSnapshot } from "valtio";
import { Text } from "./";

interface IReminderCircleProgressProps {
  data: any;
  className?: string;
  onClick?: (data) => void;
  goToDetailPage?: boolean;
  returnURL?: string | null;
  forOwner?: boolean;
  currentKilometer?: number;
  plate?: string;
  updateNeeded?: boolean;
}

const ReminderCircleProgress: React.FC<IReminderCircleProgressProps> = React.memo(
  ({
    data,
    onClick,
    goToDetailPage = false,
    returnURL = ConfigLink.homePage,
    forOwner = false,
    currentKilometer = 0,
    className = "",
    plate,
    updateNeeded = false,
  }) => {
    const snap = useSnapshot(vState);
    const history = useHistory();

    const expiresType = data.service.expiresType;

    const today = moment();
    const nextChangeTime =
      data.createdAt && data.expiresInDays ? moment(data.createdAt).add(data.expiresInDays, "days") : null;
    const isTimePassed = nextChangeTime ? nextChangeTime.diff(today, "days") <= 0 : false;
    const differenceTiming = nextChangeTime ? nextChangeTime.diff(today, "days") : 0;

    const currentKM = forOwner ? currentKilometer : snap.carStatus.kilometer ? +snap.carStatus.kilometer : 0;

    const nextKM = +(data.expiresInKilometers + data.kilometer);
    const remainKM = nextKM - currentKM;
    const kilometerPercentage =
      currentKM > nextKM || remainKM === 0 ? 100 : 100 - +(remainKM / data.expiresInKilometers).toFixed(2) * 100;
    const timePercentage = !nextChangeTime
      ? 100
      : 100 - +(+nextChangeTime.diff(today, "days") / data.service.expiresInDays).toFixed(2) * 100;

    const percentage = expiresType !== "days" ? kilometerPercentage : timePercentage;
    return (
      <>
        <span
          onClick={() => {
            if (!forOwner) {
              if (!goToDetailPage && onClick) {
                onClick(data);
              } else {
                ServiceStorage.setTempReturnURL(returnURL);
                history.push({
                  pathname: ConfigLink.reminderDetail,
                  search: useQueryString({
                    id: data.id,
                    currentKilometer: snap.carStatus.kilometer,
                    ...(updateNeeded ? { plate } : undefined),
                  }),
                });
              }
            }
          }}
          className="flex flex-col justify-center items-center cursor-pointer"
        >
          <span className="ts-progress-circle relative flex justify-center items-center">
            <GradientCircularProgress
              size={54}
              strokeWidth={8}
              emptyColor="#D5DDE5"
              startColor={percentage > 80 ? "#F5BBBB" : "#5825D6"}
              middleColor={percentage > 80 ? "#CA2525" : "#5825D6"}
              endColor={percentage > 80 ? "#F5BBBB" : "#3287EB"}
              progress={percentage < 0 || !data.committed ? 0 : percentage}
            />
            <Text
              type="P5"
              className={`${
                percentage > 80 ? "ts-secondary-red" : "ts-progress-text"
              } absolute z-20 ts-progress-hint-text`}
            >
              {expiresType !== "days" ? "KM" : "M"}
            </Text>
          </span>
          {expiresType !== "days" ? (
            <>
              <Text
                type="H6"
                className={`${
                  percentage > 80 ? "ts-secondary-red" : "ts-progress-text"
                } text-center my-2 w-20 truncate`}
              >
                {`${
                  !data.committed ? data.expiresInKilometers : remainKM <= 0 ? "0" : Math.abs(nextKM - currentKM)
                } کیلومتر`}
              </Text>
            </>
          ) : (
            <Text type="P4" className={`ts-progress-text text-center my-2`}>
              {`${!data.committed ? +data.expiresInDays / 30 : Math.abs(Math.round(differenceTiming / 30))} ${
                differenceTiming / 30 > 1 ? "ماه" : "روز"
              }`}
            </Text>
          )}
          <Text type="HM" className="text-gray-06 w-20 text-center truncate">
            {data.service.name.includes("بازدید") ? "بازدید" : data.service.name.includes("تعویض") ? "تعویض" : ""}
            <br />
            {data.service.name.includes("بازدید")
              ? data.service.name.split("بازدید")[1]
              : data.service.name.includes("تعویض")
              ? data.service.name.split("تعویض")[1]
              : data.service.name}
          </Text>
        </span>
      </>
    );
  },
);

export { ReminderCircleProgress };

const styles = {};
