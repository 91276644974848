/* eslint-disable */
import { axios } from "./api";

interface IPlate {
  plateNumber: string;
  carId: string | null;
  carName?: string;
  monthlyUsage?: string;
  buildYear?: number;
  fuelType?: string;
}

interface IReminder {
  expiresInKilometer?: number;
  expiresInDays?: number;
  service: string;
  expiresInDate?: string;
  description?: string;
}

const baseUrl = "/plates";
const plates = {
  getUserCars: async (forSetup = false) => {
    const url = `${baseUrl}?pageSize=20${forSetup ? "&setup=false" : ""}`;
    const result = await axios.get(url);

    return result;
  },
  getSingleCar: async (id: string) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.get(url);

    return result;
  },
  addCar: async (data: IPlate) => {
    const url = `${baseUrl}`;
    const result = await axios.post(url, data);

    return result;
  },
  editCar: async (id: string, data: IPlate) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.put(url, data);

    return result;
  },
  archiveCar: async (id: string) => {
    const url = `${baseUrl}/${id}/archive`;
    const result = await axios.put(url);

    return result;
  },
  getPlateReminders: async (id: string) => {
    const url = `${baseUrl}/${id}/reminders?committed=true`;
    const result = await axios.get(url);

    return result;
  },
  updateCarProperties: async (
    id: string,
    data: { mobile: string | null; plateNumber: string; ownerName: string | null; carName: string },
  ) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.put(url, data);

    return result;
  },
  getLastSession: async (id: string) => {
    const url = `${baseUrl}/${id}/last_session`;
    const result = await axios.get(url);

    return result;
  },
  addReminder: async (id: string, data: IReminder) => {
    const url = `${baseUrl}/${id}/reminders`;
    // init({auth: true});
    const result /*:Promise<IRootResponse<IUserInfo>>*/ = await axios.post(url, data);
    return result;
  },
  updateReminder: async (plate: string, id: string, data: IReminder) => {
    const url = `${baseUrl}/${plate}/reminders/${id}`;
    const result = await axios.put(url, data);
    return result;
  },
  getOwnerReport: async (id: string) => {
    const url = `${baseUrl}/${id}/report`;
    const result = await axios.get(url);

    return result;
  },
  getOwnerReportForKilometer: async (id: string) => {
    const url = `${baseUrl}/${id}/lastKilometer`;
    const result = await axios.get(url);

    return result;
  },
  checkPlateExists: async (value: string) => {
    const url = `${baseUrl}/get_by_plate_number?value=${value}`;
    const result = await axios.get(url);

    return result;
  },
  getOwnerReminders: async (id: string, sort: "kilometer" | "expiry_date" = "kilometer") => {
    const url = `${baseUrl}/${id}/reminders?pageSize=20`;
    const params = new URLSearchParams([
      ["orderBy", "uncommitted"],
      ["orderBy", `kilometer`],
      ["orderBy", "date"],
    ]);

    const result = await axios.get(url, { params });

    return result;
  },
};
export { plates };
