/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Input, Radio, RadioGroup } from "rsuite";
import { useSnapshot } from "valtio";
import { ApiInstance, vState } from "../../core";
import { useAuth } from "../../hooks";
import { BottomModal, Button, Text, useNotification } from "./";

interface IOptions {
  id: string;
  label: string;
  value: string;
}

interface IReportProblemProps {
  isOpen: boolean;
  onClose: () => void;
  id: string;
  options: IOptions[];
}

const ReportProblem: React.FC<IReportProblemProps> = React.memo(({ isOpen, onClose, id, options }) => {
  const history = useHistory();
  const auth = useAuth();
  const snap = useSnapshot(vState);
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [othersDisabled, setOthersDisabled] = useState(true);
  const [selectedItem, setSelectedItem] = useState("");
  const [otherText, setOtherText] = useState("");

  useEffect(() => {
    setButtonDisabled(selectedItem.length === 0 || (selectedItem === "other" && otherText.length === 0));
    setOthersDisabled(selectedItem !== "other");
  }, [selectedItem]);

  useEffect(() => {
    setButtonDisabled(otherText.length === 0);
  }, [otherText]);

  useEffect(() => {
    setSelectedItem("");
    setOtherText("");
  }, [isOpen]);

  const reportHandler = async () => {
    setLoading(true);
    const description = selectedItem !== "other" ? null : otherText;

    const data: any = {
      reporteeType: "product",
      reporteeId: id,
      reason: selectedItem,
      ...(description ? { description } : undefined),
    };

    await ApiInstance.provider
      .reportProblem(data)
      .then((res) => {
        console.log(res);
        useNotification("مشکل گزارش شده دریافت شد.", "success", "گزارش مشکل یا تخلف");
        setTimeout(() => {
          onClose();
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error", "گزارش مشکل یا تخلف");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <BottomModal isOpen={isOpen} onClose={onClose}>
      <div className="w-full flex flex-col justify-start items-start">
        <Text type="H4" className="ts-accent mb-2">
          گزارش مشکل یا تخلف
        </Text>
        <Text type="P3" className="ts-gray-06">
          گزارش شما ناشناس باقی خواهد ماند. همکاران ما پس از بررسی با شما تماس خواهند گرفت.
        </Text>
        <Text type="H5" className="ts-gray-08 my-6">
          دلیل گزارش خود را انتخاب کنید.
        </Text>
        <RadioGroup name="radio-name" value={selectedItem} onChange={(v: any) => setSelectedItem(v)}>
          {options.map((item) => {
            return (
              <Radio key={item.id} className="ts-text-hm ts-accent mb-3" value={item.value}>
                {item.label}
              </Radio>
            );
          })}
        </RadioGroup>

        <Text type="H6" className={`transition-all ${othersDisabled ? "ts-gray-04" : "ts-accent"} mb-2`}>
          دلیل گزارش خود را شرح دهید.
        </Text>
        <Input
          as="textarea"
          value={otherText}
          disabled={othersDisabled}
          onChange={(e) => setOtherText(e)}
          rows={5}
          className={`w-full transition-all duration-200 rounded-xl mb-6 outline-none placeholder:text-gray-300 disabled:bg-white`}
          placeholder="توضیحات برای مشکل یا تخلف"
        />

        <div className="w-full flex justify-between items-center">
          <Button
            onClick={reportHandler}
            disabled={buttonDisabled}
            loading={loading}
            bcolor="primary"
            textType="H6"
            classNameContainer="ts-half-width"
            className="w-full rounded-xl h-12"
          >
            ارسال گزارش
          </Button>

          <Button
            onClick={() => onClose()}
            bcolor="primaryTransparent"
            textType="H6"
            classNameContainer="ts-half-width"
            className="w-full rounded-xl h-12"
          >
            لغو
          </Button>
        </div>
      </div>
    </BottomModal>
  );
});

export { ReportProblem };

const styles = {};
