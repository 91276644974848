import { vState } from "../valtio";
import { keycloak } from "../../keycloak";
import { ServiceStorage } from "../services";

const env = import.meta.env;

const ConfigApp = {
  clearSearchHeaderSession: () => {
    sessionStorage.removeItem("KEY_SEARCH_HEADER");
  },
  isRunningStandalone: () => window.matchMedia("(display-mode: standalone)").matches,
  enableGoogleAnalytics: () => {},
  enableHotjar: () => {},
  initKeycloak: () => {
    keycloak
      .init({
        onLoad: "check-sso",
        silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
        // redirectUri: window.location.href,
      })
      .then((authenticated) => {
        console.log("authenticated: ", authenticated);
        if (!authenticated) {
          ServiceStorage.removeToken();
          ServiceStorage.removeAuth();
        }
      })
      .catch((err) => {
        console.log("ERROR KEYCLOAK", err);
        vState.hasError = true;
      });
  },
};

export { ConfigApp };
