export const kmAvg = [
  {
    label: "۱۰,۰۰۰+ کیلومتر (خیلی پرکار)",
    value: "VeryHigh",
  },
  {
    label: "۵۰۰۰ تا ۱۰,۰۰۰ کیلومتر (پرکار)",
    value: "High",
  },
  {
    label: "۱۰۰۰ تا ۵۰۰۰ کیلومتر (متوسط)",
    value: "Normal",
  },
  {
    label: "کمتر از ۱۰۰۰ کیلومتر (کم کار)",
    value: "Low",
  },
  {
    label: "نمی دانم",
    value: "IDK",
  },
];

export const periodTimes = [
  {
    label: "هیچوقت",
    value: "never",
  },
  {
    label: "دو هفته یکبار",
    value: "two_weeks",
  },
  {
    label: "ماهی یکبار",
    value: "one_months",
  },
  {
    label: "دو ماه یکبار",
    value: "two_months",
  },
  {
    label: "سه ماه یکبار",
    value: "three_months",
  },
];

export const daysOfWeek = ["یکشنبه", "دوشنبه", "سه شنبه", "چهارشنبه", "پنجشنبه", "جمعه", "شنبه"];

export const fuelTypes = [
  {
    label: "بنزین",
    value: "petrol",
  },
  {
    label: "گازوئیل",
    value: "gasoline",
  },
  {
    label: "دوگانه سوز(CNG)",
    value: "cng",
  },
  {
    label: "دوگانه سوز(LPG)",
    value: "lpg",
  },
  {
    label: "هیبرید",
    value: "hybrid",
  },
];

export const positiveItems = [
  {
    id: 0,
    label: "دقت در انجام کار",
    score: 1,
    value: "accuracy",
  },
  {
    id: 1,
    label: "سرعت بالای انجام کار",
    score: 1,
    value: "efficiency",
  },
  {
    id: 2,
    label: "کامل بودن تجهیزات",
    score: 1,
    value: "equipped",
  },
  {
    id: 3,
    label: "کاربلدی، تشخیص خوب",
    score: 1,
    value: "expert",
  },
  {
    id: 4,
    label: "ثبت با حوصله یادآورها",
    score: 1,
    value: "addReminder",
  },
  {
    id: 5,
    label: "استفاده از قطعه با کیفیت",
    score: 1,
    value: "qualityPiece",
  },
  {
    id: 6,
    label: "اخلاق خوب و با حوصله",
    score: 1,
    value: "wellBehaved",
  },
  {
    id: 7,
    label: "قیمت مناسب",
    score: 1,
    value: "fairPrice",
  },
  {
    id: 8,
    label: "محیط تمیز",
    score: 1,
    value: "clean",
  },
  {
    id: 9,
    label: "شاگردهای خوب",
    score: 1,
    value: "skilledAide",
  },
];

export const negativeItems = [
  {
    id: 0,
    label: "بی دقتی در انجام کار",
    score: 0,
    value: "accuracy",
  },
  {
    id: 1,
    label: "کندی در انجام کار",
    score: 0,
    value: "efficiency",
  },
  {
    id: 2,
    label: "کامل نبودن تجهیزات",
    score: 0,
    value: "equipped",
  },
  {
    id: 3,
    label: "عدم تشخیص درست",
    score: 0,
    value: "expert",
  },
  {
    id: 4,
    label: "ثبت نکردن یادآورها",
    score: 0,
    value: "addReminder",
  },
  {
    id: 5,
    label: "استفاده از قطعه بی کیفیت",
    score: 0,
    value: "qualityPiece",
  },
  {
    id: 6,
    label: "برخورد نامناسب",
    score: 0,
    value: "wellBehaved",
  },
  {
    id: 7,
    label: "قیمت زیاد",
    score: 0,
    value: "fairPrice",
  },
  {
    id: 8,
    label: "محیط کثیف",
    score: 0,
    value: "clean",
  },
  {
    id: 9,
    label: "شاگردهای بد",
    score: 0,
    value: "skilledAide",
  },
];

export const workshopTypes = [
  {
    label: "اتو سرویس (تعویض روغنی)",
    viewLabel: "اتو سرویس",
    value: "oil_change",
  },
  {
    label: "تعمیرگاه مکانیکی",
    viewLabel: "مکانیکی",
    value: "repair_shop",
  },
  {
    label: "باطری سازی",
    viewLabel: "باطری سازی",
    value: "battery_charger",
  },
  {
    label: "جلوبندی",
    viewLabel: "جلوبندی",
    value: "front_suspension",
  },
  {
    label: "کارواش",
    viewLabel: "کارواش",
    value: "car_wash",
  },
  {
    label: "لوازم یدکی",
    viewLabel: "لوازم یدکی",
    value: "after_market",
  },
  {
    label: "تنظیم موتور",
    viewLabel: "تنظیم موتور",
    value: "engine_tuning",
  },
  {
    label: "کولر خودرو",
    viewLabel: "کولر خودرو",
    value: "ac",
  },
  {
    label: "صافکاری",
    viewLabel: "صافکاری",
    value: "body_shop",
  },
  {
    label: "تنظیم فرمان",
    viewLabel: "تنظیم فرمان",
    value: "steering_wheel_tuning",
  },
  {
    label: "آپاراتی",
    viewLabel: "آپاراتی",
    value: "tire_repair",
  },
  {
    label: "لنت کوبی",
    viewLabel: "لنت کوبی",
    value: "repair_brake_linings",
  },
];
