/* eslint-disable */
import React from "react";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { HiCalendar } from "react-icons/hi";
import DatePicker from "react-multi-date-picker";
import { Text } from "./Text";

const DIR_TYPE = {
  start: "start",
  end: "end",
  center: "center",
  between: "between",
} as const;
type DIR_TYPE = (typeof DIR_TYPE)[keyof typeof DIR_TYPE];

interface IPersianDatePickerProps {
  value: string;
  onChange: (date: any) => void;
  className?: string;
  defaultLabel?: string;
  disabled?: boolean;
}

const PersianDatePicker: React.FC<IPersianDatePickerProps> = React.memo(
  ({ value, onChange, className, defaultLabel = "تاریخ حدودی", disabled = false }) => {
    const weekDays = ["ش", "ی", "د", "س", "چ", "پ", "ج"];

    return (
      <DatePicker
        value={value}
        editable={false}
        onChange={onChange}
        weekDays={weekDays}
        // minDate={new DateObject({ calendar: persian })}
        calendar={persian}
        locale={persian_fa}
        containerClassName="w-full"
        className="purple"
        inputClass="w-full"
        disabled={disabled}
        mapDays={({ date }) => {
          //disable friday
          let isWeekend = [6, 6].includes(date.weekDay.index);

          if (isWeekend)
            return {
              disabled: false,
              style: { color: "#900" },
            };
        }}
        render={(value, openCalendar) => {
          return (
            <div
              onClick={openCalendar}
              className="w-full h-12 mb-3 px-4 py-3 rounded-xl ts-input-border flex justify-between items-center"
            >
              <Text type="P5" className={`${value ? "ts-gray-07" : "ts-accent"}`}>
                {value || defaultLabel}
              </Text>
              <HiCalendar size={23} className="ts-accent" />
            </div>
          );
        }}
      />
    );
  },
);

export { PersianDatePicker };

const styles = {};
