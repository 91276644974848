/* eslint-disable */
import React, { useContext, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { CustomProvider } from "rsuite";
import { QueryParamProvider } from "use-query-params";
import { AuthProvider } from "./auth";
import { ConfigLink, vState } from "./core";
import { AppContext, AppProvider } from "./core/contexts/context.app";
import { ConfigFetcher } from "./ui/components";
import { LoadingCustom } from "./ui/components/LoadingPage";

const env = import.meta.env;
//================= [Solo Pages] =================
//Layouts
const HomePageLayout = React.lazy(() => import("./ui/_layouts/HomePageLayout"));
const IntroLayout = React.lazy(() => import("./ui/_layouts/IntroLayout"));
const WorkspaceLayout = React.lazy(() => import("./ui/_layouts/WorkspaceLayout"));
const SearchLayout = React.lazy(() => import("./ui/_layouts/SearchLayout"));
const ProfileLayout = React.lazy(() => import("./ui/_layouts/ProfileLayout"));
const CarLayout = React.lazy(() => import("./ui/_layouts/CarLayout"));
const TempLinksLayout = React.lazy(() => import("./ui/_layouts/TempLinkLayout"));
const ShopLayout = React.lazy(() => import("./ui/_layouts/ShopLayout"));

//Solo pages
const Storybook = React.lazy(() => import("./ui/views/story/StoryBook"));
const Error404 = React.lazy(() => import("./ui/views/404/Error404"));

import { Guard } from "ui/components/Guard";
import { configs } from "./configs/configs";
import { RedirectPage } from "./ui/views/redirect";

const App = () => {
  const appContext = useContext(AppContext);
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });
  const isLargeScreen = useMediaQuery({
    query: "(min-width: 920px)",
  });

  useEffect(() => {
    // if (env.REACT_APP_SHOW_DEBUG_FACILITIES === "1")
    //   if (!document.body.classList.contains("debug-screens")) document.body.classList.add("debug-screens");
    vState.isMobileDevice = isMobile;

    // ENABLE/DISABLE Console Logs
    if (configs().prod === "true") {
      console.log = function () {};
    }
  }, []);

  useEffect(() => {
    vState.isMobileDevice = isMobile;
  }, [isMobile]);

  return (
    <AuthProvider>
      <AppProvider>
        <CustomProvider>
          <Router basename="/">
            <QueryParamProvider ReactRouterRoute={Route}>
              <React.Suspense fallback={<LoadingCustom />}>
                <ConfigFetcher>
                  <Switch>
                    {/* ------- home pages -------- */}
                    <Route path={ConfigLink.homePage} exact>
                      <Guard forceAuthenticated>
                        <HomePageLayout />
                      </Guard>
                    </Route>
                    <Route path={ConfigLink.support} exact>
                      <Guard forceAuthenticated>
                        <HomePageLayout />
                      </Guard>
                    </Route>

                    <Route path={ConfigLink.redirect} exact>
                      <RedirectPage />
                    </Route>

                    {/* ------- workspace pages -------- */}
                    <Route path={ConfigLink.createCarWelcome} exact>
                      <Guard forceAuthenticated>
                        <WorkspaceLayout />
                      </Guard>
                    </Route>
                    <Route path={ConfigLink.createCar} exact>
                      <Guard forceAuthenticated>
                        <WorkspaceLayout />
                      </Guard>
                    </Route>
                    <Route path={ConfigLink.createCarRegisterJourney} exact>
                      <Guard forceAuthenticated>
                        <WorkspaceLayout />
                      </Guard>
                    </Route>
                    <Route path={ConfigLink.createCarRegisterKilometer} exact>
                      <Guard forceAuthenticated>
                        <WorkspaceLayout />
                      </Guard>
                    </Route>
                    <Route path={ConfigLink.createCarRegisterSteps} exact>
                      <Guard forceAuthenticated>
                        <WorkspaceLayout />
                      </Guard>
                    </Route>

                    {/* ------- profile pages -------- */}
                    <Route path={ConfigLink.profile} exact>
                      <Guard forceAuthenticated>
                        <ProfileLayout />
                      </Guard>
                    </Route>
                    <Route path={ConfigLink.account} exact>
                      <Guard forceAuthenticated>
                        <ProfileLayout />
                      </Guard>
                    </Route>
                    <Route path={ConfigLink.setting} exact>
                      <Guard forceAuthenticated>
                        <ProfileLayout />
                      </Guard>
                    </Route>
                    <Route path={ConfigLink.contactUs} exact>
                      <Guard forceAuthenticated>
                        <ProfileLayout />
                      </Guard>
                    </Route>
                    <Route path={ConfigLink.invite} exact>
                      <Guard forceAuthenticated>
                        <ProfileLayout />
                      </Guard>
                    </Route>
                    <Route path={ConfigLink.partRequest} exact>
                      <Guard forceAuthenticated>
                        <ProfileLayout />
                      </Guard>
                    </Route>
                    <Route path={ConfigLink.partRequestDetail} exact>
                      <Guard forceAuthenticated>
                        <ProfileLayout />
                      </Guard>
                    </Route>

                    <Route path={ConfigLink.completeAccount} exact>
                      <Guard forceAuthenticated>
                        <IntroLayout />
                      </Guard>
                    </Route>

                    {/* ------- registerPlate & carStatus pages -------- */}
                    <Route path={ConfigLink.carStatus} exact>
                      <Guard forceAuthenticated>
                        <CarLayout />
                      </Guard>
                    </Route>
                    <Route path={ConfigLink.garage} exact>
                      <Guard forceAuthenticated>
                        <CarLayout />
                      </Guard>
                    </Route>
                    <Route path={ConfigLink.reminderDetail} exact>
                      <Guard forceAuthenticated>
                        <CarLayout />
                      </Guard>
                    </Route>
                    <Route path={ConfigLink.history} exact>
                      <Guard forceAuthenticated>
                        <CarLayout />
                      </Guard>
                    </Route>
                    <Route path={ConfigLink.historyDetail} exact>
                      <Guard forceAuthenticated>
                        <CarLayout />
                      </Guard>
                    </Route>

                    {/* ------- search pages -------- */}
                    <Route path={ConfigLink.search} exact>
                      <Guard forceAuthenticated>
                        <SearchLayout />
                      </Guard>
                    </Route>
                    <Route path={ConfigLink.workshopInfo} exact>
                      <Guard forceAuthenticated>
                        <SearchLayout />
                      </Guard>
                    </Route>

                    {/* ------- shop -------- */}
                    <Route path={ConfigLink.shop} exact>
                      <Guard forceAuthenticated>
                        <ShopLayout />
                      </Guard>
                    </Route>

                    <Route path={ConfigLink.myPartDetail} exact>
                      <Guard forceAuthenticated>
                        <ShopLayout />
                      </Guard>
                    </Route>

                    <Route path={ConfigLink.intro} component={IntroLayout} exact />
                    <Route path={ConfigLink.completeInvitation} exact>
                      <Guard forceAuthenticated>
                        <IntroLayout />
                      </Guard>
                    </Route>

                    <Route path={ConfigLink.tempLink} component={TempLinksLayout} exact />
                    <Route path={ConfigLink.tempLinkSecondTime} component={TempLinksLayout} exact />
                    <Route path={ConfigLink.storybook} component={Storybook} exact />
                    <Route component={Error404} />
                  </Switch>
                </ConfigFetcher>
              </React.Suspense>
            </QueryParamProvider>
          </Router>
        </CustomProvider>
      </AppProvider>
    </AuthProvider>
  );
};

export default App;
