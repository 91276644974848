/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { MdAddCircle } from "react-icons/md";
import EmptyStateImage from "../../assets/images/welcome-image-3.svg";
import { ConfigLink, ServiceStorage } from "../../core";
import { Button } from "./Buttons";
import { Image } from "./Image";
import { Text } from "./Text";

interface INoWorkspaceEmptyStateProps {
  onClicked: () => void;
}

const NoWorkspaceEmptyState: React.FC<INoWorkspaceEmptyStateProps> = React.memo(({ onClicked }) => {
  const history = useHistory();

  useEffect(() => {}, []);

  return (
    <div className="w-full ts-shadow rounded-xl p-6 bg-white flex flex-col justify-center items-center ts-low-height">
      <Image src={EmptyStateImage} className="mb-8" />
      <Text type="P3" className="ts-gray-08 ts-letter-space text-center">
        برای مشاهده اطلاعات، خودرو خود را اضافه کنید.
      </Text>
      <Button
        bcolor="primary"
        textType="H6"
        icon={<MdAddCircle size={20} className="text-white -mt-1" />}
        classNameContainer="w-full"
        className="w-full h-12 rounded-xl ts-letter-space mt-7"
        onClick={onClicked}
      >
        اضافه کردن خودرو
      </Button>
    </div>
  );
});

export { NoWorkspaceEmptyState };

const styles = {};
