import React from "react";
import { ConfigLink } from "./configLinks";

//home
const homePage = React.lazy(() => import("../../ui/views/Home/HomePage"));
const support = React.lazy(() => import("../../ui/views/Home/Support"));

//introduction
const intro = React.lazy(() => import("../../ui/views/Introductions/Introduction"));
const completeAccount = React.lazy(() => import("../../ui/views/Introductions/CompleteAccount"));
const completeInvitation = React.lazy(() => import("../../ui/views/Introductions/CompleteInvite"));

//workspace
const setupIntro = React.lazy(() => import("../../ui/views/Workspace/SetupIntro"));
const createCar = React.lazy(() => import("../../ui/views/Workspace/AddCarPage"));
const beginJourney = React.lazy(() => import("../../ui/views/Workspace/BeginJourney"));
const registerKilometer = React.lazy(() => import("../../ui/views/Workspace/RegisterKilometer"));
const journeySteps = React.lazy(() => import("../../ui/views/Workspace/SetupSteps"));

//profile
const profile = React.lazy(() => import("../../ui/views/Profile/UserProfile"));
const account = React.lazy(() => import("../../ui/views/Profile/Account"));
const setting = React.lazy(() => import("../../ui/views/Profile/Setting"));
const contactUs = React.lazy(() => import("../../ui/views/Profile/ContactUs"));
const invite = React.lazy(() => import("../../ui/views/Profile/Invite"));
const partRequest = React.lazy(() => import("../../ui/views/Profile/PartRequest"));
const partRequestDetail = React.lazy(() => import("../../ui/views/Profile/PartRequestDetail"));

//cars
const carStatus = React.lazy(() => import("../../ui/views/Car/CarStatus"));
const garage = React.lazy(() => import("../../ui/views/Car/Garage"));
const reminderDetail = React.lazy(() => import("../../ui/views/Car/ReminderDetail"));
const history = React.lazy(() => import("../../ui/views/Car/History"));
const historyDetail = React.lazy(() => import("../../ui/views/Car/HistoryDetail"));

const setLocation = React.lazy(() => import("../../ui/views/Location/SetLocation"));

//search
const search = React.lazy(() => import("../../ui/views/SearchWorkshops/Search"));
const workshopInfo = React.lazy(() => import("../../ui/views/SearchWorkshops/WorkshopInfo"));

//shop
const shop = React.lazy(() => import("../../ui/views/Shop/MyShop"));
const myPartDetail = React.lazy(() => import("../../ui/views/Shop/MyPartDetail"));

//tempLink
const tempLink = React.lazy(() => import("../../ui/views/TempLink/OwnerReminders"));
const tempLinkSecondTime = React.lazy(() => import("../../ui/views/TempLink/OwnerPreRegister"));

//================== home route
export const routes_home = [
  {
    path: ConfigLink.homePage,
    component: homePage,
  },
  {
    path: ConfigLink.support,
    component: support,
  },
];

//================== intro route
export const routes_intro = [
  {
    path: ConfigLink.intro,
    component: intro,
  },
  {
    path: ConfigLink.completeAccount,
    component: completeAccount,
  },
  {
    path: ConfigLink.completeInvitation,
    component: completeInvitation,
  },
];

//================== workspace route
export const routes_workspaces = [
  {
    path: ConfigLink.createCarWelcome,
    component: setupIntro,
  },
  {
    path: ConfigLink.createCar,
    component: createCar,
  },
  {
    path: ConfigLink.createCarRegisterJourney,
    component: beginJourney,
  },
  {
    path: ConfigLink.createCarRegisterKilometer,
    component: registerKilometer,
  },
  {
    path: ConfigLink.createCarRegisterSteps,
    component: journeySteps,
  },
];

//================== profile route
export const routes_profile = [
  {
    path: ConfigLink.profile,
    component: profile,
  },
  {
    path: ConfigLink.account,
    component: account,
  },
  {
    path: ConfigLink.setting,
    component: setting,
  },
  {
    path: ConfigLink.contactUs,
    component: contactUs,
  },
  {
    path: ConfigLink.invite,
    component: invite,
  },
  {
    path: ConfigLink.partRequest,
    component: partRequest,
  },
  {
    path: ConfigLink.partRequestDetail,
    component: partRequestDetail,
  },
];

//================== cars routes
export const routes_cars = [
  {
    path: ConfigLink.carStatus,
    component: carStatus,
  },
  {
    path: ConfigLink.garage,
    component: garage,
  },
  {
    path: ConfigLink.reminderDetail,
    component: reminderDetail,
  },
  {
    path: ConfigLink.history,
    component: history,
  },
  {
    path: ConfigLink.historyDetail,
    component: historyDetail,
  },
];

//================== search route
export const routes_search = [
  {
    path: ConfigLink.search,
    component: search,
  },
  {
    path: ConfigLink.workshopInfo,
    component: workshopInfo,
  },
];

//================== shop routes
export const routes_shop = [
  {
    path: ConfigLink.shop,
    component: shop,
  },
  {
    path: ConfigLink.myPartDetail,
    component: myPartDetail,
  },
];

//================== temp route
export const route_temp = [
  {
    path: ConfigLink.tempLink,
    component: tempLink,
  },
  {
    path: ConfigLink.tempLinkSecondTime,
    component: tempLinkSecondTime,
  },
];
