/* eslint-disable */
import { axios } from "./api";

interface IRequest {
  name: string;
  car: string;
  description?: string;
}

const baseUrl = "/parts";

const partSupply = {
  getRequests: async () => {
    const url = `${baseUrl}`;
    const result = await axios.get(url);

    return result;
  },
  addRequests: async (data: IRequest) => {
    const url = `${baseUrl}`;
    const result = await axios.post(url, data);

    return result;
  },
  getRequestInfo: async (id: string) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.get(url);

    return result;
  },
  editRequestInfo: async (id: string, data: IRequest) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.put(url, data);

    return result;
  },
  deleteRequestInfo: async (id: string, reason: string) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.delete(url, {
      data: { reason },
    });

    return result;
  },
};

export { partSupply };
