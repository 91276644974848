// import { useAuth } from "#hooks";
import { ConfigLink } from "core";
import { useAuth } from "hooks";
import { FC, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { LoadingCustom } from "./LoadingPage";

interface GuardProps {
  forceAuthenticated?: boolean;
  forceUnauthenticated?: boolean;
}

export const Guard: FC<GuardProps> = ({ children, forceAuthenticated, forceUnauthenticated }) => {
  // show loading if we need to wait for authentication service
  const [ready, setReady] = useState<boolean>(forceAuthenticated === undefined && forceUnauthenticated === undefined);
  const auth = useAuth();
  const history = useHistory();
  const isLargeScreen = useMediaQuery({
    query: "(min-width: 920px)",
  });

  useEffect(() => {
    if (auth.ready) {
      setReady(true);

      if (!auth.authenticated && forceAuthenticated) {
        auth.login({
          redirectUri: window.location.href,
        });
      } else if (auth.authenticated && forceUnauthenticated) {
        history.replace(ConfigLink.homePage);
      }
    }
  }, [auth.ready, auth.authenticated]);

  // show loading if authentication result is not ready yet
  if (!ready) {
    return <LoadingCustom />;
  }

  // if (isLargeScreen && forceNotLargeScreen) {
  //   return <LargeScreenWarn />;
  // }

  return <>{children}</>;
};
