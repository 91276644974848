import { IConfigurations } from "./types";

const env = import.meta.env;

export const devConfigs: IConfigurations = {
  endpointUrl: env.REACT_APP_ENDPOINT_URL,
  imageUrl: env.REACT_APP_IMAGE_URL,
  keycloakClient: env.REACT_APP_KEYCLOAK_CLIENT_ID,
  keycloakRealm: env.REACT_APP_KEYCLOAK_REALM,
  keycloakUrl: env.REACT_APP_KEYCLOAK_URL,
  logoType: env.REACT_APP_LOGO_TYPE,
  mapApi: env.REACT_APP_MAP_API,
  locationToAddressApi: env.REACT_APP_LOCATION_TO_ADDRESS_API,
  showDebugFacilities: env.REACT_APP_SHOW_DEBUG_FACILITIES,
  prod: env.REACT_APP_PROD,
};
