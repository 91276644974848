import { proxy } from "valtio";
import { UserProfile } from "./../interfaces/dto/User";

interface ILocation {
  type: string;
  coordinates: [number, number];
}

interface ICarInfo {
  plate: string;
  carType: string;
  fuelType: string;
  year: string;
  kilometer: string;
}

interface IUserInfo {
  groups: any[];
  id: string;
  mobileVerified: boolean;
  username: string;
  mobile: string;
  name: string;
}

interface IPLateInfo {
  plateNumber: string;
  car: any;
  carName: string;
  carId?: string;
  mobile: string;
  ownerName?: string;
  kilometer: number;
  id: string;
}

interface ICarList {
  id: string;
  createdAt: string;
  kilometer: number;
  plate: {
    id: string;
    plateNumber: string;
    mobile: string;
    car: {
      id: string;
      name: string;
    };
  };
  status: string;
  updatedAt: string;
}

interface IRecentlySearches {
  id: number | string;
  plateNumber: string;
}

interface IOwnerCars {
  carName: string;
  carId?: string;
  car?: any;
  id: string;
  kilometer?: number | undefined;
  kilomeeterCreatedAt?: string;
  mobile: string;
  ownerName: any;
  owner: string | null;
  plateNumber: string;
  updatedAt?: string | undefined;
  buildYear?: number | undefined;
  fuelType?: string | undefined;
  monthlyUsage?: string | undefined;
}

interface ISelectPicker {
  label: string;
  value: number | string;
}

export interface IVersion {
  number: number;
  code: number;
  note: string;
}

type scroll = "up" | "down";

export interface IVState {
  cities: any[];
  cars: any[];
  services: any[];
  categories: ISelectPicker[];
  version: IVersion;
  carsModalOpen: boolean;
  activeFeatures: string[];

  userInfo: UserProfile;
  recentlySearches: IRecentlySearches[];
  carList: ICarList[];
  carLoading: boolean;
  selectedPlate: IPLateInfo;
  reminders: any[];
  newRemindersCount: number;
  sessionId: string;

  //------------------------------------------------owner
  pageLoading: boolean;
  hasError: boolean;
  isMobileDevice: boolean;
  isScrolling: boolean;
  isCarStatusScroll: boolean;
  isAuthenticated: boolean;
  isEndOfPage: boolean;

  ownerCars: IOwnerCars[];
  carStatus: IOwnerCars;
  userCar: ICarInfo;
  setupServices: any[];
}

const vState = proxy({
  pageLoading: true,
  hasError: false,
  isMobileDevice: false,
  isScrolling: false,
  isCarStatusScroll: false,
  isEndOfPage: false,
  version: {
    number: 0,
    code: 0,
    note: "",
  },
  carsModalOpen: false,
  activeFeatures: [],

  cities: [],

  cars: [],
  services: [],
  categories: [],

  userInfo: {
    id: "",
    name: "",
    username: "",
    phoneNumber: "",
    roles: [],
    mobile: "",
    userProfileId: "",
  },
  recentlySearches: [],
  carList: [],
  carLoading: true,

  reminders: [],
  newRemindersCount: 0,
  selectedPlate: {
    plateNumber: "",
    car: {},
    carName: "",
    carId: "",
    mobile: "",
    ownerName: "",
    kilometer: 0,
    id: "",
  },
  sessionId: "",
  isAuthenticated: false,

  //--------------------------------------owner

  ownerCars: [],
  carStatus: {
    carName: "",
    id: "",
    kilometer: 0,
    mobile: "",
    ownerName: "",
    plateNumber: "",
    updatedAt: "",
    buildYear: 0,
    fuelType: "",
    monthlyUsage: "",
    owner: null,
  },
  userCar: {
    plate: "",
    carType: "",
    fuelType: "",
    year: "",
    kilometer: "",
  },
  setupServices: [],
} as IVState);

export { vState };
