/* eslint-disable */
import { axios } from "./api";
import { vState } from "../valtio";

const baseUrl = '/sessions';
const sessions = {
    getReminders: async(id:string,sort:'kilometer'|'expiry_date' = 'kilometer') => {
        const url = `/plates/${id}/reminders`;
        const params = new URLSearchParams([['orderBy','uncommitted'],['orderBy',`kilometer`],['orderBy','date']]);
        
        const result = await axios.get(url, {params})

        return result
    },
    getRecommendationService: async(id:string) => {
        const url = `/services/${id}/recommendations`;
        
        const result = await axios.get(url)

        return result
    },
};
export { sessions };
