/* eslint-disable */
/* @ts-ignore */
import React, { useEffect } from "react";
import { FormProps, Input } from "rsuite";
import { Text } from "./";
import { TEXT_TYPE } from "./Text";

interface IInputFieldProps extends FormProps {
  label?: any;
  // name: string;
  labelFont?: TEXT_TYPE;
  labelClassName?: string;
  inputType?: "text" | "number" | "price";
  fieldType?: "input" | "textarea";
  rows?: number;
  error?: boolean;
  errorText?: string;
  hint?: string;
  placeholder?: string;
  margin?: string;
  containerClassName?: string;
  value: any;
  onChange?: (e?: any) => void;
  onFocus?: any;
}

const InputField: React.FC<IInputFieldProps> = React.memo(
  ({
    fieldType = "input",
    // name,
    label,
    labelFont = "H6",
    labelClassName = "",
    error = false,
    errorText = "",
    hint = "",
    inputType = "text",
    placeholder = "",
    margin = "mb-6",
    value,
    containerClassName,
    rows = 4,
    onChange,
    onFocus,
    ...restProps
  }) => {
    //@ts-ignore
    const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    useEffect(() => {}, []);

    return (
      <div className={`w-full flex flex-col justify-start items-start ${margin} ${containerClassName}`}>
        {label && (
          <Text type={labelFont} className={`mb-2 ts-accent ${labelClassName}`}>
            {label}
          </Text>
        )}
        <div className={`w-full relative`}>
          {fieldType === "input" ? (
            <Input
              value={value}
              // name={name}
              type={isIOS() ? "text" : inputType === "number" ? "number" : inputType === "price" ? "tel" : "text"}
              pattern={(inputType === "number" || inputType === "price") && isIOS() ? "[0-9]*" : undefined}
              onWheel={(e) => {
                // Prevent the input value change
                // @ts-ignore
                e.target.blur();

                // Prevent the page/container scrolling
                e.stopPropagation();

                // Refocus immediately, on the next tick (after the current function is done)
                setTimeout(() => {
                  // @ts-ignore
                  e.target.focus();
                }, 0);
              }}
              onChange={onChange}
              onFocus={onFocus}
              className={`w-full rounded-xl transition-all bg-transparent ${
                isIOS() ? "" : inputType === "price" ? "ts-rtl-direction" : ""
              } outline-none h-12 placeholder:text-gray-300 ${error ? "ts-error-border" : ""}`}
              placeholder={placeholder}
            />
          ) : (
            <Input
              value={value}
              as="textarea"
              rows={rows}
              onChange={onChange}
              onFocus={onFocus}
              className={`w-full rounded-xl bg-transparent outline-none ${
                error ? "ts-error-border" : ""
              } placeholder:text-gray-300`}
              placeholder={placeholder}
            />
          )}
          {hint && (
            <Text type="P4" className="ts-gray-06 absolute z-20 ts-tooman-text left-3">
              {hint}
            </Text>
          )}
        </div>
        {error && (
          <Text type="P5" className={`ts-error mt-1`}>
            {errorText || "پر کردن این فیلد الزامی است"}
          </Text>
        )}
      </div>
    );
  },
);

export { InputField };

const styles = {};
