/* eslint-disable */
import axios from "axios";
import { neshanAPI } from "./neshanApi";

const map = {
  searchOnMap: async (search: string, lat: number, lng: string) => {
    const url = `/v1/search?term=${search}&lat=${lat}&lng=${lng}`;
    const result = await neshanAPI.get(url);

    return result;
  },
  getUserIp: async () => {
    const result = await axios.get("https://api.ipify.org/?format=json");

    return result;
  },
  getUserLocation: async (ip) => {
    const result = await axios.get(`https://freeipapi.com/api/json/${ip}`);

    return result;
  },
};
export { map };
