import { useAuth } from "hooks";
import { useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Loader } from "rsuite";
import { StringParam, useQueryParams } from "use-query-params";

export const RedirectPage = () => {
  const history = useHistory();
  const [query] = useQueryParams({
    to: StringParam,
    mobile: StringParam,
  });

  const auth = useAuth();
  const mobile = useMemo(() => (auth.profile ? auth.profile.mobile : undefined), [auth.profile]);

  useEffect(() => {
    if (!query.to || !query.mobile) {
      history.replace("/");
      return;
    }

    if (auth.ready) {
      if (!auth.authenticated) {
        auth.login({
          redirectUri: window.location.href,
          loginHint: query.mobile,
        });
        return;
      }

      if (mobile === query.mobile) {
        history.replace(decodeURIComponent(query.to));
      } else {
        auth.logout({
          redirectUri: window.location.href,
        });
      }
    }
  }, [auth.ready, mobile, auth.authenticated, query.to, query.mobile]);

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <Loader size="sm" center />
    </div>
  );
};
