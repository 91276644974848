/* eslint-disable */
import qs from "qs";
import { axios } from "./api";

interface IPart {
  name: string;
  car: string;
  price: number | string;
  manufacture: string;
  grouping: string;
  manufacturingCountry: string;
  code?: string;
  description?: string;
}

const baseUrl = "/shops";

const shop = {
  getParts: async (
    page: number,
    sort: "DATE" | "NAME" | "",
    query: string | null,
    filter: any,
    grouping: string | null,
  ) => {
    const url = `${baseUrl}`;
    const result = await axios.get(url, {
      params: {
        pageSize: 50,
        page,
        ...(sort ? { sort } : undefined),
        ...(query ? { query } : undefined),
        ...(filter.car ? { carId: filter.car.id } : undefined),
        ...(filter.manufacture ? { manufacture: filter.manufacture } : undefined),
        ...(filter.manufacturingCountry ? { manufacturingCountry: filter.manufacturingCountry } : undefined),
        // ...(filter.cityId ? { cityId: filter.cityId } : undefined),
        ...(grouping ? { grouping } : undefined),
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });

    return result;
  },
  getPartInfo: async (id: string) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.get(url);

    return result;
  },
};

export { shop };
