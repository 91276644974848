/* eslint-disable */

const ConfigLink = {
  setParam: (url: string, paramName: string, paramValue: string) => {
    return url.replace(paramName, paramValue);
  },
  //Storybook
  storybook: "/storybook",
  //Page Not Found
  pageNotFound: "/page-not-found",

  //profile
  profile: "/profile",
  account: "/profile/account",
  setting: "/profile/setting",
  contactUs: "/profile/contact",
  invite: "/profile/invite",
  partRequest: "/profile/part-request",
  partRequestDetail: "/profile/part-request/detail",

  //dashboard
  homePage: "/",
  carStatus: "/car-status",

  //login-register
  login: "/sign-up",

  //introductions
  intro: "/intro",
  completeAccount: "/complete-account",
  completeInvitation: "/complete-invite",

  //workspace
  createCarWelcome: "/setup/welcome",
  createCar: "/setup/add-car",
  createCarRegisterJourney: "/setup/begin-journey",
  createCarRegisterKilometer: "/setup/register-kilometer",
  createCarRegisterSteps: "/setup/journey/steps",

  createCarLocation: "/create-workspace/add-location",

  //cars
  garage: "/garage",
  reminderDetail: "/reminder-detail",
  history: "/garage/service-history",
  historyDetail: "/garage/service-history/detail",

  //search
  search: "/search",
  workshopInfo: "/workshop/info",

  // shop
  shop: "/shop",
  myPartDetail: "/shop/part-detail",

  //temp_link
  tempLink: "/owners",
  tempLinkSecondTime: "/ml",

  // support
  support: "/support",

  redirect: "/redirect"
};
export { ConfigLink };
