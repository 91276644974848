import { useContext } from "react";
import { AuthContext } from "../auth";
import { keycloak } from "../keycloak";
import { KeycloakLoginOptions, KeycloakLogoutOptions } from "keycloak-js";

export const useAuth = () => {
  const authContext = useContext(AuthContext);

  return {
    ready: authContext.ready,
    authenticated: authContext.authenticated,
    login: (options?: KeycloakLoginOptions) => keycloak.login(options),
    logout: (options?: KeycloakLogoutOptions) => keycloak.logout(options),
    register: () => keycloak.register(),
    profile: authContext.profile,
    token: keycloak.idToken,
  };
};
