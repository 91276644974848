/* eslint-disable */
import { digitsFaToEn } from "@persian-tools/persian-tools";
import { ServiceStorage } from "..";

export const tools = {
  isObject: (data) => {
    return data && typeof data === "object";
  },
  toObject(data) {
    return this.isObject(data) ? data : {};
  },
  makeCapitalCase: (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.toLowerCase().slice(1);
  },
  isEmailValid: (email) => {
    var re =
      /^(([^<>()\[\]\.,;:\s@"]+(\.[^<>()\[\]\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
    return re.test(String(email).toLowerCase());
  },
  justNumber: (str) => {
    let result = str.replace(/[^\d]/g, "");
    return result;
  },
  justEnglish: (value) => {
    return `${value}`.replace(/[^a-zA-Z\s]/g, "");
  },
  jsonDecode: (json) => {
    try {
      return JSON.parse(json);
    } catch (e) {
      return null;
    }
  },
  scrollToTop: (ref) => {
    ref.current.scrollTo(0, 0);
  },

  /**
   * Find specific field error message from errorArray
   * @param {{field: String, errorMessage: String}[]} errorArray
   * @param {string} fieldName the field's name that you want to look for into errorArray
   * @return {string} field error message if found. otherwise return empty string
   */
  findErrorMessage: (errorArray, fieldName) => {
    const t = errorArray.find((x) => x.field === fieldName);
    if (t) {
      return t.errorMessage;
    } else {
      return "";
    }
  },
  /**
   * Get Validation model, and input data object then return result array that we can store it into errors Array. if there is no error, it will be empty
   * @param {Schema} validationModelSchema
   * @param {Object} inputObject object of values that contain field's name for each input
   * @return {{field: String, errorMessage: String}[]}
   */
  applyModelCheckValidation: (validationModelSchema, inputObject) => {
    let result: { field: string; errorMessage: string }[] = [];
    const checkResult = validationModelSchema.check(inputObject);
    for (let key in checkResult) {
      let value = checkResult[key];
      if (value.hasError) {
        result.push({ field: key, errorMessage: value.errorMessage });
      }
    }
    return result;
  },
  // converts number to string representation with K and M.
  // toFixed(d) returns a string that has exactly 'd' digits
  // after the decimal place, rounding if necessary.
  numFormatterToTruncate: (num) => {
    if (num === null || num === undefined) return "---";
    //---
    if (num > 999 && num < 1000000) {
      return (num / 1000) % 1 === 0 ? num / 1000 + "K" : (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000) % 1 === 0 ? num / 1000000 + "M" : (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 900) {
      return num; // if value < 1000, nothing to do
    }
  },
  thousandSeparator: (number) => {
    if (number) {
      let n = parseFloat(number);
      return n.toLocaleString();
    } else {
      return number;
    }
  },
  isLocalhost: () =>
    Boolean(
      window.location.hostname === "localhost" ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === "[::1]" ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
    ),
  returnURLProcedure: (): string => {
    //---redirect to return url
    let ru = ServiceStorage.getTempReturnURL();
    let _url = "/"; //default route path as home address
    if (ru) {
      console.log("redirect to return url after check login--==>>", ru);
      _url = ru;
      // remove return url
      ServiceStorage.setTempReturnURL(null);
    }
    return _url;
  },
  convertFileCapacity: (bytes: any, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  },
  getFormattedPlate: (firstSection, letter, secondSection, thirdSection) => {
    return `${digitsFaToEn(firstSection)}${letter}${digitsFaToEn(secondSection)}-${digitsFaToEn(thirdSection)}`;
  },
  getFullPlate: (plate: string) => {
    return `ایران ${plate.substring(7, 9)}-${plate.substring(3, 6)} ${
      plate.slice(2, 3) === "ا" ? "الف" : plate.slice(2, 3)
    } ${plate.slice(0, 2)}`;
  },
  isIOS: () => {
    // @ts-ignore
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  },
  convertDateToPersianDate: (
    date: string,
    withHour: boolean = false,
    withWeekDay: boolean = false,
    shortVersion: boolean = true,
  ) => {
    return `${
      withWeekDay
        ? new Date(date).toLocaleDateString("fa-IR", {
            weekday: "long",
          })
        : ""
    } ${new Date(date).toLocaleDateString("fa-IR", {
      day: "numeric",
      month: shortVersion ? "numeric" : "long",
      year: "numeric",
    })} ${
      withHour
        ? `ساعت ${new Date(date).toLocaleTimeString("fa-IR", {
            hour: "numeric",
            minute: "numeric",
          })}`
        : ""
    }`;
  },
  convertDateToIranTime: (date: string) => {
    return date
      ? `${new Date(date).toLocaleTimeString("fa-IR", {
          hour: "numeric",
          minute: "numeric",
        })}`
      : "";
  },
};
