/* eslint-disable */
import { axios } from "./api";

const baseUrl = "/cars";

const car = {
  updateCarKilometer: async (id: string, kilometer: number) => {
    const url = `/plates/${id}/kilometer`;
    const result = await axios.post(url, { kilometer });

    return result;
  },
  deleteReminder: async (id: string, serviceId: string) => {
    const url = `/plates/${id}/reminders?serviceId=${serviceId}`;
    const result = await axios.delete(url);

    return result;
  },
  getCarHistory: async (id: string) => {
    const url = `/plates/${id}/sessions`;
    const result = await axios.get(url);

    return result;
  },
  getHistoryDetail: async (plateId: string, session: string) => {
    const url = `/plates/${plateId}/sessions/${session}`;
    const result = await axios.get(url);

    return result;
  },
  removeFromSetup: async (id: string) => {
    const url = `/plates/${id}/setup`;
    const result = await axios.put(url);

    return result;
  },
  getCarBrands: async () => {
    const url = `${baseUrl}/brands`;
    const result = await axios.get(url);

    return result;
  },
  getCarModels: async (id: string) => {
    const url = `${baseUrl}/${id}/models`;
    const result = await axios.get(url);

    return result;
  },
  getCarTypes: async (id: string) => {
    const url = `${baseUrl}/${id}/types`;
    const result = await axios.get(url);

    return result;
  },
};
export { car };
