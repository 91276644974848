//@ts-nocheck
/* eslint-disable */
import React, { Suspense, useCallback, useEffect, useState } from "react";
import { useImage } from "react-image";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Loader } from "rsuite";
import { ErrorBoundary } from "./";
import { img_not_available } from "./ErrorBoundary";

interface ILoadingImageProps {
  srcImg: string | string[];
  alt?: string;
  className?: string;
  style?: any;
}
interface IImageProps {
  src: any;
  alt?: string;
  className?: string;
  width?: string;
  height?: string;
  onClick?: () => void;
  thumbnail?: boolean;
  ErrorImage?: any;
}

const LoadingImage: React.FC<ILoadingImageProps> = ({ srcImg, alt = "", className, style = {} }) => {
  const { src } = useImage({
    srcList: srcImg,
  });
  // using backgronud image
  return (
    <div
      className={className}
      style={{
        ...style,
        width: "100%",
        height: "100%",
        backgroundImage: `url(${srcImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
      }}
    />
  );

  // using image element
  // return <img src={src} alt={alt} className={`${className}`} style={style} />;
};

const Image: React.FC<IImageProps> = ({
  src,
  alt = "",
  className,
  width,
  height,
  onClick,
  thumbnail = false,
  ErrorImage = null,
}) => {
  const [hasError, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {}, []);

  const onClickHandler = useCallback(() => {
    if (onClick) onClick();
  }, []);

  return (
    <div className="ts-image border-0 overflow-hidden" style={{ width, height }} onClick={onClickHandler}>
      {thumbnail ? (
        <ErrorBoundary className={className} style={{ width, height }}>
          <Suspense fallback={<Loader className="loader-spinner z-10" size="xs" />}>
            <LoadingImage srcImg={src} className={`${className} bg-cover`} style={{ width, height }} alt={alt} />
          </Suspense>
        </ErrorBoundary>
      ) : (
        <>
          {hasError ? (
            <LoadingImage
              srcImg={ErrorImage || img_not_available}
              className={`${className} bg-cover`}
              style={{ width, height }}
              alt={alt}
            />
          ) : (
            <>
              <LazyLoadImage
                alt={alt}
                className={`${className} bg-cover max-w-full object-cover`}
                style={{ height }}
                src={src}
                afterLoad={() => setLoading(false)}
                width={"100%"}
                height={height}
                effect={loading ? "blur" : "opacity"}
                onError={() => setError(true)}
              />
              {loading && <Loader className="loader-spinner z-10" size="xs" />}
            </>
          )}
        </>
      )}
    </div>
  );
};

export { Image };
