/* eslint-disable */
import React, { useState, useEffect } from "react";
import { BottomModal, Button, CustomModal, FullDivider, Text } from "./";
import { useHistory } from "react-router-dom";
import { ApiInstance, ConfigLink, ServiceStorage, useQueryString, vState } from "../../core";
import { useSnapshot } from "valtio";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";

interface IUpdateLogModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const UpdateLogModal: React.FC<IUpdateLogModalProps> = React.memo(({ isOpen, onClose }) => {
  const history = useHistory();
  const snap = useSnapshot(vState);

  const [showMore, setShowMore] = useState(false);
  const [noteHeight, setNoteHeight] = useState(0);

  useEffect(() => {
    if (noteHeight > 12) {
      document.getElementsByClassName("rs-modal-content")[0]?.classList.add("ts-hide-scroll-but-keep-working");
    }
  }, [noteHeight, showMore]);

  useEffect(() => {
    const div = document.getElementById("ts-version-note-div");
    if (div) {
      const lineHeight = parseInt(window.getComputedStyle(div).getPropertyValue("line-height"));
      const height = div.clientHeight;
      const numberOfLines = Math.round(height / lineHeight);
      // console.log(numberOfLines);
      setNoteHeight(numberOfLines);
    }
  });

  const ignoreHandler = () => {
    ServiceStorage.setVersionVisited(snap.version.number.toString().replaceAll(".", ""));
    onClose();
  };

  return (
    <BottomModal
      centerScreen
      staticBackdrop
      isOpen={isOpen}
      onClose={onClose}
      className={`${showMore ? "h-auto" : "ts-version-note-height overflow-hidden"} p-6 rounded-xl relative`}
    >
      <Text type="H4" className="ts-accent mb-6 text-center">
        {`ویراژ به نسخه ${snap.version.number} بروزرسانی شد`}
      </Text>
      <Text id="ts-version-note-div" type="P4" className="ts-accent mb-28 whitespace-pre-wrap">
        {`${snap.version.note}`}
      </Text>
      <div className="w-full fixed bottom-8 left-0 pb-6 px-6 rounded-b-xl bg-white">
        <div className="w-full flex justify-center items-center mb-4 relative">
          {noteHeight > 12 && (
            <Button
              bcolor="transparent"
              iconPosition="afterText"
              icon={
                showMore ? (
                  <BiChevronUp size={21} className="-mt-px ts-link" />
                ) : (
                  <BiChevronDown size={21} className="-mt-px ts-link" />
                )
              }
              onClick={() => setShowMore((prev) => !prev)}
              className="ts-link"
              textType="H6"
            >
              {showMore ? "مشاهده کمتر" : "مشاهده جزئیات بیشتر"}
            </Button>
          )}
          {!showMore && noteHeight > 12 && <div className="ts-show-more-shadow"></div>}
        </div>
        <Button
          bcolor="primary"
          onClick={ignoreHandler}
          classNameContainer="w-full"
          className="w-full h-12 rounded-xl"
          textType="H6"
        >
          متوجه شدم
        </Button>
      </div>
    </BottomModal>
  );
});

export { UpdateLogModal };

const styles = {};
