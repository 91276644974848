import { account } from "./account";
import { car } from "./car";
import { common } from "./common";
import { map } from "./map";
import { partSupply } from "./partSupply";
import { plates } from "./plates";
import { provider } from "./provider";
import { sessions } from "./sessions";
import { shop } from "./shop";

const ApiInstance = {
  account,
  car,
  plates,
  common,
  sessions,
  provider,
  map,
  partSupply,
  shop,
};

export { ApiInstance };
