/* eslint-disable */
import React, { useEffect } from "react";
import { Badge, Button as RButton, ButtonProps } from "rsuite";
import { Text } from "../";

const COLOR_TYPE = {
  primary: "primary",
  primaryLight: "primaryLight",
  primaryTransparent: "primaryTransparent",
  black: "black",
  blackTransparent: "blackTransparent",
  white: "white",
  whiteTransparent: "whiteTransparent",
  transparent: "transparent",
  green: "green",
  greenTransparent: "greenTransparent",
  gray: "gray",
  gray1: "gray1",
  red: "red",
  redTransparent: "redTransparent",
} as const;
type COLOR_TYPE = (typeof COLOR_TYPE)[keyof typeof COLOR_TYPE];

const ICON_POS_TYPE = {
  beforeText: "beforeText",
  afterText: "afterText",
} as const;
type ICON_POS_TYPE = (typeof ICON_POS_TYPE)[keyof typeof ICON_POS_TYPE];

const TEXT_TYPES = {
  H1: "H1",
  H2: "H2",
  H3: "H3",
  H4: "H4",
  H5: "H5",
  H6: "H6",
  Logo: "Logo",
  HM: "HM",
  P1: "P1",
  P2: "P2",
  P3: "P3",
  P4: "P4",
  P5: "P5",
} as const;
export type TEXT_TYPES = (typeof TEXT_TYPES)[keyof typeof TEXT_TYPES];

interface IButtonProps extends ButtonProps {
  bcolor?: COLOR_TYPE;
  dicPattern?: string | any[];
  className?: string;
  classNameContainer?: string;
  textType?: TEXT_TYPES;
  icon?: JSX.Element;
  iconPosition?: ICON_POS_TYPE;
  fluid?: boolean;
  showBadgeOnIcon?: boolean;
  disabled?: boolean;
  style?: any;
}

const Button: React.FC<IButtonProps> = ({
  bcolor,
  icon,
  iconPosition = "beforeText",
  dicPattern,
  className,
  textType = "H6",
  classNameContainer,
  style = {},
  children,
  fluid = false,
  showBadgeOnIcon = false,
  disabled = false,
  ...restProps
}) => {
  useEffect(() => {}, []);

  const IconComponent = icon && React.cloneElement(icon, { className: `icon mx-1 ${icon.props.className}` });

  return (
    <div className={`ts-button ${classNameContainer}`}>
      <RButton
        block={fluid}
        size="md"
        disabled={disabled}
        {...restProps}
        className={`${
          bcolor === "primary"
            ? "primary"
            : bcolor === "primaryLight"
            ? "primary-light"
            : bcolor === "primaryTransparent"
            ? "primary-transparent"
            : bcolor === "black"
            ? "black"
            : bcolor === "blackTransparent"
            ? "blackTransparent"
            : bcolor === "white"
            ? "white"
            : bcolor === "whiteTransparent"
            ? "whiteTransparent"
            : bcolor === "transparent"
            ? "trans-transparent"
            : bcolor === "green"
            ? "green"
            : bcolor === "greenTransparent"
            ? "green-transparent"
            : bcolor === "gray"
            ? "gray"
            : bcolor === "gray1"
            ? "gray1"
            : bcolor === "red"
            ? "red"
            : bcolor === "redTransparent"
            ? "red-transparent"
            : "default"
        } ${className}`}
        style={{ ...style }}
      >
        <div className={`flex flex-row justify-center items-center ${disabled ? "disabled" : ""}`}>
          {iconPosition === "beforeText" && !restProps.loading && IconComponent}
          {/* {children?.valueOf() !== "string" ? (
            children
          ) : ( */}
          <Text type={textType} className={`${disabled ? "disabled" : ""} ts-letter-space-min`}>
            {children}
          </Text>
          {/* )} */}
          {iconPosition === "afterText" &&
            !restProps.loading &&
            (showBadgeOnIcon ? <Badge>{IconComponent}</Badge> : IconComponent)}
        </div>
      </RButton>
    </div>
  );
};

export { Button };

const styles = {};
